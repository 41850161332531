import React from "react";
import style from "./Login.module.scss";

import { useLogin } from "./useLogin";

export default function Login() {
  const { handleChangeForm, handleSubmitForm } = useLogin();

  return (
    <div className={style.wrapper}>
      <form onChange={handleChangeForm} onSubmit={handleSubmitForm}>
        <div className={style.row}>
          <label>Username</label>
          <input type="text" name="username" />
        </div>
        <div className={style.row}>
          <label>Password</label>
          <input type="password" name="password" />
        </div>
        <div className={style.row}>
          <button>Login</button>
        </div>
      </form>
    </div>
  );
}
