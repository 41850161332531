import create from "zustand";

const useStore = create((set) => ({
  formData: {},
  setFormData: (payload) => set(() => ({ formData: payload })),

  storage: {},
  setLocalStorage: (payload) => set(() => ({ storage: payload })),
}));

export default useStore;
