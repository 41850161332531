import Navbar from "components-layout/Navbar";
import React from "react";

export default function WithLayout({ children }) {
  return (
    <>
      <Navbar />
      {children}
    </>
  );
}
