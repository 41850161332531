import React from "react";
import style from "./Preloader.module.scss";

import ClipLoader from "react-spinners/ClipLoader";

export default function Preloader() {
  return (
    <div className={style.wrapper}>
      <ClipLoader color={"#7a0ed9"} loading={true} size={150} />
    </div>
  );
}
