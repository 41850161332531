import { postLogin } from "apis/users";
import useStore from "./Login.store";

import { useNavigate } from "react-router-dom";

export function useLogin() {
  const navigate = useNavigate();

  const { formData, setFormData, storage, setLocalStorage } = useStore((state) => ({
    formData: state.formData,
    setFormData: state.setFormData,

    storage: state.storage,
    setLocalStorage: state.setLocalStorage,
  }));

  function handleChangeForm(e) {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }

  function handleSubmitForm(e) {
    e.preventDefault();

    postLogin(formData).then((resp) => {
      localStorage.setItem("authToken", resp.data.data.token);
      setLocalStorage(resp.data.data.token)
      navigate("/");
    });
  }

  return { handleChangeForm, handleSubmitForm };
}
