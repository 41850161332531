import { getRequest, postRequest } from "./setup";

export function postLogin(body) {
  return postRequest(`/users/auth/login`, body);
}

export function fetchAllCandidates(options) {
  //return getRequest(`/admin/students/`);

  let url = "/admin/students";

  if (options && options.query) {
    let queryString = `?`;
    Object.entries(options.query).forEach(([key, value]) => {
      if (value !== null && value !== undefined)
        queryString += `&${key}=${value}`;
    });
    url += queryString;
  }
  return getRequest(url);
}
export function fetchTestResponses(testAttemptId) {
  return getRequest(`/users/tests/responses/${testAttemptId}`);
}

export function fetchUserTest(testAttemptUid) {
  return getRequest(`/users/tests/${testAttemptUid}`);
}

export function fetchUserProfile() {
  return getRequest(`/users/profile`);
}

export function postStudent(body) {
  return postRequest(`/admin/students`, body);
}
