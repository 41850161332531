import React from "react";
import Select from "react-select";

const FONT_SIZE = "14px";

export default function ReactSelect({
  onChange,
  options,
  width,
  form,
  ...props
}) {
  const colourStyles = {
    container: (styles, { isFocused }) => ({
      ...styles,
      width: width ? width : "350px",
      height: "35px",
      borderRadius: "20px !important",
      cursor: "pointer !important",
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      boxShadow: "unset !important",
      borderColor: "rgba(1,1,1,0.2) !important",
      fontSize: FONT_SIZE,
      backgroundColor: "white",
      width: "100%",
      minHeight: "0px",
      cursor: "pointer !important",
      borderRadius: "10px",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        fontSize: FONT_SIZE,
        backgroundColor: isSelected ? "#a34791" : "white",
        cursor: "pointer !important",
        "&:hover": {
          backgroundColor: "rgba(1,1,1,0.1)",
        },
      };
    },
    input: (styles) => ({
      ...styles,
      color: "transparent",
      height: "35px !important",
      cursor: "pointer !important",
    }),
    menu: (styles) => ({
      ...styles,
      cursor: "pointer !important",
    }),
  };
  return (
    <Select
      {...props}
      options={options}
      styles={colourStyles}
      onChange={onChange}
    />
  );
}
