import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Preloader from "components-shared/Preloader";
import TestAdd from "components/TestAdd";

import useAppStore from "stores/AppStore";
import { fetchUserProfile } from "apis/users";

import Login from "Login";

import useLoginStore from "../Login/Login.store"

const QuesAdd = lazy(() => import("components/QuesAdd"));
const InstructionsAdd = lazy(() => import("components/InstructionsAdd"));
const QuesView = lazy(() => import("components/QuesView"));
const TestView = lazy(() => import("components/TestView"));
const TestEdit = lazy(() => import("components/TestEdit"));
const TestQuesAdd = lazy(() => import("components/TestEdit/TestQuesAdd"));
const InstructionsView = lazy(() => import("components/InstructionsView"));
const TestAttempts = lazy(() => import("components/TestAttempts"));
const SubjectsView = lazy(() => import("components/SubjectsView"));
const SubjectsAdd = lazy(() => import("components/SubjectsAdd"));
const StudentsView = lazy(() => import("components/StudentsView"));

export default function AppRouter() {
  const { setUserProfile } = useAppStore((state) => ({
    setUserProfile: state.setUserProfile,
  }));

  const { storage } = useLoginStore((state) => ({
    storage: state.storage,
  }));

  function getUserProfile() {
    fetchUserProfile()
      .then((resp) => {
        setUserProfile(resp.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if ("authToken" in localStorage) getUserProfile();
  }, [storage]);

  return (
    <RouterWrapper>
      <Suspense fallback={<Preloader />}>
        <Routes>
          <Route path="/" element={<Navigate to="/questions" replace />} />

          <Route path="/questions" element={<QuesView />} />
          <Route path="/questions/add" element={<QuesAdd />} />

          <Route path="/instructions" element={<InstructionsView />} />
          <Route path="/instructions/add" element={<InstructionsAdd />} />

          <Route path="/tests" element={<TestView />} />
          <Route path="/tests/add" element={<TestAdd />} />
          <Route path="/test/edit/:testId" element={<TestEdit />} />

          <Route
            path="/test/edit/add-questions/:testId"
            element={<TestQuesAdd />}
          />

          <Route path="/attempts" element={<TestAttempts />} />

          <Route path="/subjects" element={<SubjectsView />} />
          <Route path="/subjects/add" element={<SubjectsAdd />} />

          <Route path="/students" element={<StudentsView />} />

          <Route path="/auth/login" element={<Login />} />
        </Routes>
      </Suspense>
    </RouterWrapper>
  );
}

function RouterWrapper({ children }) {
  return <BrowserRouter>{children}</BrowserRouter>;
}
