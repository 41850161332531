import React from "react";
import style from "./WithPadding.module.scss";

import clsx from "clsx";

export default function WithPadding({ className, id, children }) {
  return (
    <div id={id} className={clsx(style.wrapper, className)}>
      {children}
    </div>
  );
}
